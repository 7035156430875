import { Outlet } from "react-router-dom";

const Main = () => {
  return (
    <>

      <div id="" className="">
        <Outlet />
      </div>
    </>
  );
};

export default Main;
